<template>
	<v-layout>
		<v-menu offset-y right :close-on-content-click="false">
			<template v-slot:activator="{ on }">
				<v-btn color="primary" flat :icon="isMinimized" v-on="on">
					<v-icon small>sort</v-icon>
					<span v-if="!isMinimized" v-t="'client_workspace.tasks.sorting.title'" class="mx-2" />
				</v-btn>
			</template>
			<v-list>
				<v-list-tile @click="sortByCompany">
					<v-list-tile-action>
						<SortButton
							ref="company"
							v-model="byCompany"
							:field="'byCompany'"
							:icon="'business'"
							:tooltip-asc="$t('client_workspace.tasks.sorting.company.asc')"
							:tooltip-desc="$t('client_workspace.tasks.sorting.company.desc')"
							:tooltip-cancel="$t('client_workspace.tasks.sorting.company.cancel')"
							:has-cancel="true"
							@sorted="updateSortingState"
						/>
					</v-list-tile-action>
					<v-list-tile-title>{{ $t('client_workspace.tasks.sorting.company.title') }}</v-list-tile-title>
				</v-list-tile>
				<v-list-tile @click="sortByDueDate">
					<v-list-tile-action>
						<SortButton
							ref="dueDate"
							v-model="byDueDate"
							:field="'byDueDate'"
							:icon="'update'"
							:tooltip-asc="$t('client_workspace.tasks.sorting.due_date.asc')"
							:tooltip-desc="$t('client_workspace.tasks.sorting.due_date.desc')"
							:tooltip-cancel="$t('client_workspace.tasks.sorting.due_date.cancel')"
							:has-cancel="true"
							@sorted="updateSortingState"
						/>
					</v-list-tile-action>
					<v-list-tile-title>{{ $t('client_workspace.tasks.sorting.due_date.title') }}</v-list-tile-title>
				</v-list-tile>
				<v-list-tile @click="sortByName">
					<v-list-tile-action>
						<SortButton
							ref="name"
							v-model="byName"
							:field="'byName'"
							:icon="'sort_by_alpha'"
							:tooltip-asc="$t('client_workspace.tasks.sorting.name.asc')"
							:tooltip-desc="$t('client_workspace.tasks.sorting.name.desc')"
							:tooltip-cancel="$t('client_workspace.tasks.sorting.name.cancel')"
							:has-cancel="true"
							@sorted="updateSortingState"
						/>
					</v-list-tile-action>
					<v-list-tile-title>{{ $t('client_workspace.tasks.sorting.name.title') }}</v-list-tile-title>
				</v-list-tile>
				<v-list-tile @click="sortByStatus">
					<v-list-tile-action>
						<SortButton
							ref="status"
							v-model="byStatus"
							:field="'byStatus'"
							:icon="'play_arrow'"
							:tooltip-asc="$t('client_workspace.tasks.sorting.status.asc')"
							:tooltip-desc="$t('client_workspace.tasks.sorting.status.desc')"
							:tooltip-cancel="$t('client_workspace.tasks.sorting.status.cancel')"
							:has-cancel="true"
							@sorted="updateSortingState"
						/>
					</v-list-tile-action>
					<v-list-tile-title>{{ $t('client_workspace.tasks.sorting.status.title') }}</v-list-tile-title>
				</v-list-tile>
			</v-list>
		</v-menu>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'TasksManagerToolbarSorting',
	components: {
		SortButton: () => ({
			component: import('@/components/Commons/SortButton')
		})
	},
	props: {
		minimized: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapState({
			sorting: state => state.tasks.sorting
		}),
		isMinimized: function () {
			return !this.$vuetify.breakpoint.xlAndUp || this.minimized
		},
		byCompany: function () {
			return { field: 'byCompany', order: this.sorting.byCompany }
		},
		byDueDate: function () {
			return { field: 'byDueDate', order: this.sorting.byDueDate }
		},
		byName: function () {
			return { field: 'byName', order: this.sorting.byName }
		},
		byStatus: function () {
			return { field: 'byStatus', order: this.sorting.byStatus }
		}
	},
	methods: {
		sortByCompany: function () {
			this.$refs.company.sort()
		},
		sortByDueDate: function () {
			this.$refs.dueDate.sort()
		},
		sortByName: function () {
			this.$refs.name.sort()
		},
		sortByStatus: function () {
			this.$refs.status.sort()
		},
		updateSortingState: function (sorting) {
			this.sorting.byCompany = ''
			this.sorting.byDueDate = ''
			this.sorting.byName = ''
			this.sorting.byStatus = ''
			this.sorting[sorting.field] = sorting.order
		}
	}
}
</script>
